var isSVG = function (target) {
  return target instanceof SVGElement && 'getBBox' in target;
};
var isHidden = function (target) {
  if (isSVG(target)) {
    var _a = target.getBBox(),
      width = _a.width,
      height = _a.height;
    return !width && !height;
  }
  var _b = target,
    offsetWidth = _b.offsetWidth,
    offsetHeight = _b.offsetHeight;
  return !(offsetWidth || offsetHeight || target.getClientRects().length);
};
var isElement = function (obj) {
  var _a;
  if (obj instanceof Element) {
    return true;
  }
  var scope = (_a = obj === null || obj === void 0 ? void 0 : obj.ownerDocument) === null || _a === void 0 ? void 0 : _a.defaultView;
  return !!(scope && obj instanceof scope.Element);
};
var isReplacedElement = function (target) {
  switch (target.tagName) {
    case 'INPUT':
      if (target.type !== 'image') {
        break;
      }
    case 'VIDEO':
    case 'AUDIO':
    case 'EMBED':
    case 'OBJECT':
    case 'CANVAS':
    case 'IFRAME':
    case 'IMG':
      return true;
  }
  return false;
};
export { isSVG, isHidden, isElement, isReplacedElement };