var trigger;
var callbacks = [];
var notify = function () {
  return callbacks.splice(0).forEach(function (cb) {
    return cb();
  });
};
var queueMicroTask = function (callback) {
  if (!trigger) {
    var toggle_1 = 0;
    var el_1 = document.createTextNode('');
    var config = {
      characterData: true
    };
    new MutationObserver(function () {
      return notify();
    }).observe(el_1, config);
    trigger = function () {
      el_1.textContent = "".concat(toggle_1 ? toggle_1-- : toggle_1++);
    };
  }
  callbacks.push(callback);
  trigger();
};
export { queueMicroTask };