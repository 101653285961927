import {Injectable, NgZone} from '@angular/core';
import {SearchModalComponent} from "../components/search-modal/search-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class SearchService {

  constructor(private dialog: MatDialog, private router: Router, private ngZone:NgZone, private route: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  public openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'vex-dialog-glossy',
      width: '100%',
      maxWidth: '600px'
    }).afterClosed().subscribe((redirectLink: string | null) => {
      if (redirectLink) {
        console.log('redirectLink', redirectLink);
        this.router.navigate([redirectLink], {onSameUrlNavigation: 'reload'}).then(() => {
          // this.ngZone.run(() => {
          //   console.log('Routed');
          // });
        });
      }
    });
  }

}
