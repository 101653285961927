import { Injectable } from '@angular/core';
import {DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';

@Injectable()
export class CzechDateAdapter extends NativeDateAdapter {
  private _localeData = {
    months: ['leden', 'únor', 'březen', 'duben', 'květen', 'červen','červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'],
    days: ['ne', 'po', 'út', 'st', 'čt', 'pá', 'so'],
  };

  constructor() {
    super();
    this.setLocale('cs-CZ');
  }

  // Override the default methods to implement Czech date format

 override parse(value: any): Date | null {
    console.log('parse');
    console.log(value);
    if (typeof value === 'string' && value.indexOf('.') > -1) {
      const str = value.split('.');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: string): string {
    const day = this._to2digit(date.getDate());
    const month = this._to2digit(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }

  addCalendarDays(date: Date, days: number): Date {
    return this.createDate(date.getFullYear(), date.getMonth(), date.getDate() + days);
  }

  addCalendarMonths(date: Date, months: number): Date {
    let newMonth = date.getMonth() + months;
    let newYear = date.getFullYear();
    if (newMonth > 11) {
      newYear += Math.floor(newMonth / 12);
      newMonth = newMonth % 12;
    } else if (newMonth < 0) {
      newYear += Math.floor(newMonth / 12);
      newMonth = 12 + (newMonth % 12);
    }
    return this.createDate(newYear, newMonth, date.getDate());
  }

  addCalendarYears(date: Date, years: number): Date {
    return this.createDate(date.getFullYear() + years, date.getMonth(), date.getDate());
  }

  clone(date: Date): Date {
    return new Date(date.getTime());
  }

  createDate(year: number, month: number, date: number): Date {
    const result = new Date(year, month, date);
    if (year >= 0 && year < 100) {
      result.setFullYear(year);
    }
    return result;
  }

  getYear(date: Date): number {
    return date.getFullYear();
  }

  getMonth(date: Date): number {
    return date.getMonth();
  }

  getDate(date: Date): number {
    return date.getDate();
  }

  getDayOfWeek(date: Date): number {
    return date.getDay();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this._localeData.months;
  }

  getDateNames(): string[] {
    return Array.from({ length: 31 }, (_, i) => String(i + 1));
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this._localeData.days;
  }

  getYearName(date: Date): string {
    return String(date.getFullYear());
  }

  getFirstDayOfWeek(): number {
    return 1; // Monday is the first day of the week in Czech
  }

  getNumDaysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  today(): Date {
    return new Date();
  }

  parseIso8601(value: string): Date | null {
    return new Date(value);
  }

  invalid(): Date {
    return new Date(NaN);
  }

  isValid(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  toIso8601(date: Date): string {
    return date.toISOString();
  }
}
