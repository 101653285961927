import { freeze } from './utils/freeze';
var DOMRectReadOnly = function () {
  function DOMRectReadOnly(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.top = this.y;
    this.left = this.x;
    this.bottom = this.top + this.height;
    this.right = this.left + this.width;
    return freeze(this);
  }
  DOMRectReadOnly.prototype.toJSON = function () {
    var _a = this,
      x = _a.x,
      y = _a.y,
      top = _a.top,
      right = _a.right,
      bottom = _a.bottom,
      left = _a.left,
      width = _a.width,
      height = _a.height;
    return {
      x: x,
      y: y,
      top: top,
      right: right,
      bottom: bottom,
      left: left,
      width: width,
      height: height
    };
  };
  DOMRectReadOnly.fromRect = function (rectangle) {
    return new DOMRectReadOnly(rectangle.x, rectangle.y, rectangle.width, rectangle.height);
  };
  return DOMRectReadOnly;
}();
export { DOMRectReadOnly };