import {MatPaginatorIntl} from "@angular/material/paginator";

const csRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return `0 z ${length}`;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} z ${length}`;
};

export function CsPaginator() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Položek na stránce:';
  paginatorIntl.nextPageLabel = 'Další stránka';
  paginatorIntl.previousPageLabel = 'Předchozí stránka';
  paginatorIntl.getRangeLabel = csRangeLabel;

  return paginatorIntl;
}
