import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModxClient, ModxClientWithRelations} from '../../../../@backend';

@Component({
  selector: 'canvas-client-extended',
  standalone: true,
  imports: [CommonModule],
  template: `
    <span>{{ client?.title_1 }} {{ client?.firstname }} {{ client?.lastname }} {{ client?.title_2 }}</span>
    @if(client?.company) {
      <div class="font-light text-sm">{{client.company}}
        @if(client?.ico && client?.ico !== 0 && client?.ico !== '0') {
          <span> (IČO: {{client.ico}})</span>
        }
      </div>
    }
    @if(client.phone_1) {
      <div class="font-light text-sm">{{client.phone_1}}</div>
    }
    @if(client.phone_2) {
      <div class="font-light text-sm">{{client.phone_2}}</div>
    }
    @if(client.email_1) {
      <div class="font-light text-sm">{{client.email_1}}</div>
    }
    @if(client.email_2) {
      <div class="font-light text-sm">{{client.email_2}}</div>
    }
  `
})
export class ClientExtendedComponent {
  @Input() client: ModxClient | ModxClientWithRelations | any;
  @Input() enableLink: boolean = false;

}
