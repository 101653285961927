import { calculateBoxSizes } from './algorithms/calculateBoxSize';
import { freeze } from './utils/freeze';
var ResizeObserverEntry = function () {
  function ResizeObserverEntry(target) {
    var boxes = calculateBoxSizes(target);
    this.target = target;
    this.contentRect = boxes.contentRect;
    this.borderBoxSize = freeze([boxes.borderBoxSize]);
    this.contentBoxSize = freeze([boxes.contentBoxSize]);
    this.devicePixelContentBoxSize = freeze([boxes.devicePixelContentBoxSize]);
  }
  return ResizeObserverEntry;
}();
export { ResizeObserverEntry };