import { process } from './process';
import { global } from './global';
import { queueResizeObserver } from './queueResizeObserver';
var watching = 0;
var isWatching = function () {
  return !!watching;
};
var CATCH_PERIOD = 250;
var observerConfig = {
  attributes: true,
  characterData: true,
  childList: true,
  subtree: true
};
var events = ['resize', 'load', 'transitionend', 'animationend', 'animationstart', 'animationiteration', 'keyup', 'keydown', 'mouseup', 'mousedown', 'mouseover', 'mouseout', 'blur', 'focus'];
var time = function (timeout) {
  if (timeout === void 0) {
    timeout = 0;
  }
  return Date.now() + timeout;
};
var scheduled = false;
var Scheduler = function () {
  function Scheduler() {
    var _this = this;
    this.stopped = true;
    this.listener = function () {
      return _this.schedule();
    };
  }
  Scheduler.prototype.run = function (timeout) {
    var _this = this;
    if (timeout === void 0) {
      timeout = CATCH_PERIOD;
    }
    if (scheduled) {
      return;
    }
    scheduled = true;
    var until = time(timeout);
    queueResizeObserver(function () {
      var elementsHaveResized = false;
      try {
        elementsHaveResized = process();
      } finally {
        scheduled = false;
        timeout = until - time();
        if (!isWatching()) {
          return;
        }
        if (elementsHaveResized) {
          _this.run(1000);
        } else if (timeout > 0) {
          _this.run(timeout);
        } else {
          _this.start();
        }
      }
    });
  };
  Scheduler.prototype.schedule = function () {
    this.stop();
    this.run();
  };
  Scheduler.prototype.observe = function () {
    var _this = this;
    var cb = function () {
      return _this.observer && _this.observer.observe(document.body, observerConfig);
    };
    document.body ? cb() : global.addEventListener('DOMContentLoaded', cb);
  };
  Scheduler.prototype.start = function () {
    var _this = this;
    if (this.stopped) {
      this.stopped = false;
      this.observer = new MutationObserver(this.listener);
      this.observe();
      events.forEach(function (name) {
        return global.addEventListener(name, _this.listener, true);
      });
    }
  };
  Scheduler.prototype.stop = function () {
    var _this = this;
    if (!this.stopped) {
      this.observer && this.observer.disconnect();
      events.forEach(function (name) {
        return global.removeEventListener(name, _this.listener, true);
      });
      this.stopped = true;
    }
  };
  return Scheduler;
}();
var scheduler = new Scheduler();
var updateCount = function (n) {
  !watching && n > 0 && scheduler.start();
  watching += n;
  !watching && scheduler.stop();
};
export { scheduler, updateCount };