import { BrowserModule } from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import {BASE_PATH} from '../@backend';
import {JwtInterceptor} from './shared/interceptors/jwt.interceptor';
import {ErrorInterceptor} from './shared/interceptors/error.interceptor';
import {environment} from '../environments/environment';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {CsPaginator} from "./shared/utils/paginator.translate";
import localeCs from '@angular/common/locales/cs';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {CzechDateAdapter} from "./shared/utils/custom-date-adapter";
registerLocaleData(localeCs);

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({ declarations: [
        AppComponent,
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatNativeDateModule,

        // Vex
        VexModule,
        MatSnackBarModule,
        CustomLayoutModule],
        providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: BASE_PATH, useValue: environment.baseApiUrl },
        { provide: MAT_DATE_LOCALE, useValue: 'cs-CZ' },
        { provide: LOCALE_ID, useValue: 'cs-CZ' },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        { provide: DateAdapter, useClass: CzechDateAdapter },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'Kč' },
        { provide: MatPaginatorIntl, useValue: CsPaginator() },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
